/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      text
      file
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      text
      file
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      text
      file
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSharedMix = /* GraphQL */ `
  mutation CreateSharedMix(
    $input: CreateSharedMixInput!
    $condition: ModelSharedMixConditionInput
  ) {
    createSharedMix(input: $input, condition: $condition) {
      id
      text
      file
      userId
      identityId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSharedMix = /* GraphQL */ `
  mutation UpdateSharedMix(
    $input: UpdateSharedMixInput!
    $condition: ModelSharedMixConditionInput
  ) {
    updateSharedMix(input: $input, condition: $condition) {
      id
      text
      file
      userId
      identityId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSharedMix = /* GraphQL */ `
  mutation DeleteSharedMix(
    $input: DeleteSharedMixInput!
    $condition: ModelSharedMixConditionInput
  ) {
    deleteSharedMix(input: $input, condition: $condition) {
      id
      text
      file
      userId
      identityId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
