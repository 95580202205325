/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      text
      file
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        file
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSharedMix = /* GraphQL */ `
  query GetSharedMix($id: ID!) {
    getSharedMix(id: $id) {
      id
      text
      file
      userId
      identityId
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSharedMixes = /* GraphQL */ `
  query ListSharedMixes(
    $filter: ModelSharedMixFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedMixes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        text
        file
        userId
        identityId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
