import React, { CSSProperties } from "react";
import "./App.css";
import {
  createNote,
  updateNote,
  deleteNote,
  createSharedMix,
  deleteSharedMix,
} from "./graphql/mutations";
import { listNotes, listSharedMixes } from "./graphql/queries";
import {
  withAuthenticator,
  Button,
  Text,
  Flex,
  Heading,
  TextAreaField,
  TextField,
  defaultDarkModeOverride,
  ThemeProvider,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  Link,
  Alert,
  Loader,
  Badge,
  View,
  ColorMode,
} from "@aws-amplify/ui-react";
import { Auth, Storage, graphqlOperation } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useCallback, useEffect, useState, useRef, forwardRef } from "react";
import { API } from "aws-amplify";
import { MdDarkMode, MdCancel, MdDelete, MdPause } from "react-icons/md";
import {
  BsSun,
  BsCircleHalf,
  BsFillCloudCheckFill,
  BsFillPlayFill,
  //BsPauseCircle,
  BsPencilSquare,
  BsEject,
  BsFillCheckCircleFill,
  BsCloudUpload,
} from "react-icons/bs";
import { ImBackward, ImForward2, ImPlay2 } from "react-icons/im";
import {
  List,
  CellMeasurer,
  CellMeasurerCache,
  AutoSizer,
} from "react-virtualized";
import { SiApplemusic } from "react-icons/si";
import { RiDeleteBin2Fill } from "react-icons/ri";
import { FaSignOutAlt, FaListAlt } from "react-icons/fa";
import { ReconnectingWebSocket } from "./ReconnectingWebSocket";
import { LazyLoadImage } from "react-lazy-load-image-component";
//import mixPiggyIcon from "./mixpiggy-large3.png";

// Material design
import {
  ThemeProvider as MDThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { default as MDButton } from "@mui/material/Button";
import { default as MDIconButton } from "@mui/material/IconButton";
import { default as MDDeleteIcon } from "@mui/icons-material/Delete";
import { default as MDEditIcon } from "@mui/icons-material/Edit";
import { default as MDListIcon } from "@mui/icons-material/List";
import { default as MDEjectIcon } from "@mui/icons-material/Eject";
import { default as MDCancelIcon } from "@mui/icons-material/Cancel";
import { default as MDCheckIcon } from "@mui/icons-material/Check";
import { default as MDPlayIcon } from "@mui/icons-material/PlayArrow";
import { default as MDUploadFile } from "@mui/icons-material/UploadFile";
import { default as MDDiscFull } from "@mui/icons-material/DiscFull";
import { default as MDLogout } from "@mui/icons-material/Logout";
import CssBaseline from "@mui/material/CssBaseline";
import { default as MDShareRounded } from "@mui/icons-material/ShareRounded";

type AWSStorageLevel = "protected" | "private";
interface AWSStorageConfig {
  level: AWSStorageLevel;
  identityId?: string;
  expires?: number;
}

const s3level: AWSStorageLevel = "private";
const webSockUrl =
  "wss://r0lpzq048f.execute-api.eu-central-1.amazonaws.com/production";

const useMd = true;
const log = (...p) => console.log(...p);
const headerHeight = "40px";
const songCacheStorage = window.sessionStorage; // window.localStorage;

const webSocket = ReconnectingWebSocket(webSockUrl, async () => {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();
  const cred = await Auth.currentUserCredentials();
  log("cognito user", cognitoUser, currentSession, cred);
  const userId = cognitoUser.attributes?.sub;
  const token = currentSession.getIdToken().getJwtToken();
  return {
    userId,
    token,
  };
});

const getMixCardId = (fileName) => `card-id-${fileName}`;

const sortByOrder = {
  asc: "asc",
  desc: "desc",
};
const sortBy = sortByOrder.desc;

const getUserId = (): string => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as unknown as { userId: string };
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let identityId = params.userId;
  return identityId;
};

const getMixFile = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop as string),
  }) as unknown as { mixFile: string };
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  let mixFile = params.mixFile;
  return mixFile;
};

const renderTextArea = (mix, fetchMixes, rows, isReadOnly = false) => {
  if (isReadOnly) {
    return <Flex><Text style={{ paddingTop: '6px' }}>{mix.text}</Text></Flex>;
  }
  return (
    <TextAreaField
      key={mix.file}
      style={{ marginTop: "0" }}
      autoComplete="off"
      direction="column"
      hasError={false}
      isDisabled={false}
      isReadOnly={false}
      isRequired={false}
      labelHidden={true}
      placeholder="Mix description"
      rows={rows}
      size="small"
      wrap="nowrap"
      onChange={async (e) => {
        log("onChange", e.currentTarget.value);
        await API.graphql({
          query: updateNote,
          variables: { input: { id: mix.id, text: e.currentTarget.value } },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        await fetchMixes();
      }}
      onInput={(e) => log("input fired:", e.currentTarget.value)}
      onCopy={(e) => log("onCopy fired:", e.currentTarget.value)}
      onCut={(e) => log("onCut fired:", e.currentTarget.value)}
      onPaste={(e) => log("onPaste fired:", e.currentTarget.value)}
      onSelect={(e) =>
        log(
          "onSelect fired:",
          e.currentTarget.value.substring(
            e.currentTarget.selectionStart,
            e.currentTarget.selectionEnd
          )
        )
      }
      defaultValue={mix.text}
      label={""}
    />
  );
};

const formatTitleArtist = (title, artist) => {
  if (title && artist) {
    return `${title} · ${artist}`;
  }
  if (title) {
    return title;
  }
  if (artist) {
    return artist;
  }
  return "?";
};

const timeToString = (time) => {
  let totalSeconds = time;
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  if (hours > 0)
    return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}:${
      seconds < 10 ? `0${seconds}` : seconds
    }`;
  if (seconds < 10) return `${minutes}:0${seconds}`;
  return `${minutes}:${seconds}`;
};

const renderDownloadLink = (mixFile, fileName) => {
  const fileAvailable = mixFile && fileName;
  return (
    <Text>
      {fileAvailable ? (
        <Link href={mixFile.signedUrl}>{fileName}</Link>
      ) : (
        <Loader />
      )}
    </Text>
  );
};

const useMixImages = (mixes) => {
  const [mixImages, setMixImages] = useState([]);

  const fetchMixImage = useCallback(
    async (mix) => {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        `Bearer ${currentSession.getIdToken().getJwtToken()}`
      );

      const requestOptions: RequestInit = {
        headers: myHeaders,
        redirect: "follow",
      };
      // For shared mix use sharer user id
      const response = await fetch(
        `https://tkv31gf989.execute-api.eu-central-1.amazonaws.com/dev/mixer-mp3-files/${
          mix.userId || cognitoUser.attributes?.sub
        }${mix.file}.jpeg`,
        requestOptions
      );
      mixImages.push({ songId: mix.file, image: await response.blob() });
      setMixImages(mixImages);
    },
    [mixImages]
  );

  useEffect(() => {
    for (const mix of mixes) {
      fetchMixImage(mix);
    }
  }, [mixes, fetchMixImage]);

  return {
    mixImages,
  };
};

const MixCover = ({ mixFileName, mixImages, onNewImageUploaded, audio }) => {
  function renderImageUploadButton(): React.ReactNode {
    return (
      onNewImageUploaded &&
      !getMixFile() && (
        <div
          style={{
            alignSelf: 'center',
            border: 'solid 1px gray',
            padding: '4px',
            borderRadius: '4px'
          }}
        >
          <label htmlFor="mixArtwork">
              Upload image
          </label>
          <input
            style={{ display: 'none' }}
            id="mixArtwork"
            type="file"
            accept="image/jpeg"
            onChange={async (e) => {
              const file = e.target.files[0];
              if (file) {
                const cognitoUser = await Auth.currentAuthenticatedUser();
                const currentSession = await Auth.currentSession();

                //const ext = file.name.substring(file.name.indexOf("."))
                if (!cognitoUser.attributes?.sub) {
                  return;
                }
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", file.type);
                myHeaders.append(
                  "Authorization",
                  `Bearer ${currentSession.getIdToken().getJwtToken()}`
                );

                function getFileBuffer(file): Promise<ArrayBuffer | string> {
                  return new Promise((resolve, reject) => {
                    var reader = new FileReader();
                    reader.onloadend = function (e) {
                      resolve(e.target.result);
                    };
                    reader.onerror = function (e) {
                      reject(e.target.error);
                    };
                    reader.readAsArrayBuffer(file);
                  });
                }

                const requestOptions: RequestInit = {
                  method: "PUT",
                  headers: myHeaders,
                  body: await getFileBuffer(file),
                  redirect: "follow",
                };

                const ok = await fetch(
                  `https://tkv31gf989.execute-api.eu-central-1.amazonaws.com/dev/mixer-mp3-files/${cognitoUser.attributes?.sub}${mixFileName}.jpeg`,
                  requestOptions
                );
                onNewImageUploaded();
              }
            }}
          />
        </div>
      )
    );
  }

  const m = mixImages.find((x) => x.songId === mixFileName);
  let image;
  if (m && m.image && m.image.type !== "application/json") {
    image = URL.createObjectURL(m.image);
  } else {
    if (!onNewImageUploaded) {
      return (
        <Text>
          <SiApplemusic size="100%" />
        </Text>
      );
    }
  }

  const imageBorderRadius = '40px';

  // In the list of all mixes, show default image and lazy load image if available
  if (!onNewImageUploaded) {
    return (
      <Text
        style={{
          borderRadius: imageBorderRadius,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <SiApplemusic size="100%" />
        <LazyLoadImage
          style={{ position: "absolute", left: 0 }}
          //id="myImg"
          height="100%"
          width="100%"
          src={image}
          alt="artwork"
        />
      </Text>
    );
  }

  const renderPlayControls = () => {
    const backgroundColorTile = 'rgba(255, 255, 255, 0.5)';
    const iconColor = 'rgba(0, 0, 0, 0.5)';
    return (
      <Flex
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
        }}
      >
        <div
          style={{
            backgroundColor: backgroundColorTile,
            border: 0,
            flex: '1 1 auto',
          }}
          onClick={() => {
            if (!audio.current) {
              return;
            }
            audio.current.currentTime = audio.current.currentTime - 30;
          }}
        >
          <div
            style={{
              borderRadius: imageBorderRadius,
              overflow: 'hidden',
            }}
          >
            <ImBackward
              style={{
                width: '100%',
                height: '100%',
                color: iconColor,
              }}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: backgroundColorTile,
            border: 0,
            flex: '1 1 auto',
          }}
          onClick={() => {
            if (!audio.current) {
              return;
            }
            if (!audio.current.paused) {
              audio.current.pause();
            } else {
              audio.current.play();
            }
          }}
        >
          <div>
            <ImPlay2
              style={{
                width: '100%',
                height: '100%',
                color: iconColor,
              }}
            />
          </div>
        </div>
        <div
          style={{
            backgroundColor: backgroundColorTile,
            border: 0,
            flex: '1 1 auto',
          }}
          onClick={() => {
            if (!audio.current) {
              return;
            }
            audio.current.currentTime = audio.current.currentTime + 30;
          }}
        >
          <div>
            <ImForward2
              style={{
                width: '100%',
                height: '100%',
                color: iconColor,
              }}
            />
          </div>
        </div>
      </Flex>
    );
  };

  // Edit mode in detail view
  // If we have no image yet
  if (!image) {
    return (
      <Flex direction={"column"}>
        <Flex
          style={{
            position: 'relative',
          }}
        >
          <SiApplemusic size="100%" />
          {renderPlayControls()}
        </Flex>
        {renderImageUploadButton()}
      </Flex>
    );
  }
  // If we have an image in edit mode
  return (
    <Flex direction={"column"}>
      {image && (
        <Flex
          direction={"column"}
          style={{
            borderRadius: imageBorderRadius,
            overflow: 'hidden',
            position: 'relative',
          }}
        >
          <LazyLoadImage
            id="myImg"
            height="100%"
            width="100%"
            src={image ? image : undefined}
            alt="artwork"
          />
          {renderPlayControls()}
        </Flex>
      )}
      {renderImageUploadButton()}
    </Flex>
  );
};

interface MixFile {
  fileName: string;
  size: number;
  title: string;
  artist: string;
  time: number;
}
interface MixSummaryType {
  songId: string;
  fileName: string;
}

interface MixType {
  fileName: string;
  id: string;
  file: string;
  text: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}

const Mix = forwardRef<
  HTMLDivElement,
  {
    mix: MixType;
    mixFile: MixFile;
    fileName: string;
    fetchMixes: () => void;
    handleDeleteMix: (id: string, mixFile: MixType) => void;
    playhead: (mixFile: MixFile) => void;
    sendMixSummary: (mixSummary: MixSummaryType) => void;
    mixSummaries: MixSummaryType[];
    setMixSummaries: (mixSummaries: MixSummaryType[]) => void;
    mixImages: { [key: string]: string };
    isSharedMix: boolean;
    audio: React.Ref<HTMLAudioElement>;
  }
>(
  (
    {
      mix,
      mixFile,
      fileName,
      fetchMixes,
      handleDeleteMix,
      playhead,
      sendMixSummary,
      mixSummaries,
      setMixSummaries,
      mixImages,
      isSharedMix,
      audio,
    },
    ref
  ) => {
    const [confirmRemove, setConfirmRemove] = useState(false);
    const removeClick = (mix, mixFile) => {
      if (!confirmRemove) {
        setConfirmRemove(true);
        return;
      } else {
        setConfirmRemove(false);
      }
      handleDeleteMix(mix, mixFile);
    };

    const getSummary = () =>
      mixSummaries.find((s) => s.songId === mixFile?.fileName);

    const renderSongs = (summary) => {
      if (!summary) {
        return null; // <Loader style={{ marginTop: '10px' }}/>;
      }
      return (
        <div style={{ marginTop: "10px" }}>
          {[...summary.songs.Items]
            .sort((s1, s2) => s1.time - s2.time)
            .map((s, i) => (
              <Flex
                key={s.time}
                justifyContent={"space-between"}
                style={{ borderBottom: "1px dotted #666" }}
              >
                <Text>{`${i + 1}. ${formatTitleArtist(
                  s.title,
                  s.artist
                )}`}</Text>
                <Text>{timeToString(s.time)}</Text>
              </Flex>
            ))}
        </div>
      );
    };

    const summary = getSummary();
    const fileAvailable = mixFile && fileName;
    const cover = (
      <MixCover
        mixFileName={fileName}
        mixImages={mixImages}
        onNewImageUploaded={undefined}
        audio={audio}
      />
    );
    const getMixDescription = (rows = 1) => (
      <>
        <Flex justifyContent={"space-between"}>
          {renderDownloadLink(mixFile, fileName)}
          {mixFile && (
            <Text>
              {`${(mixFile.size / 1000 / 1000).toFixed(1)} MB`}
              {isSharedMix && (
                <MDShareRounded style={{ height: '12px' }} />
              )}
            </Text>
          )}
        </Flex>
        <Flex alignItems="flex-start" style={{ marginTop: "5px" }}>
          <View>
            <MDIconButton
              disabled={!fileAvailable}
              onMouseDown={(e) => {
                e.preventDefault();
                playhead(mixFile);
              }}
            >
              <MDPlayIcon />
            </MDIconButton>
          </View>
          {/* <View>
      <Button
        disabled={!fileAvailable || !summary}
        onClick={(e) => {
          e.currentTarget.blur();

          const newMixSummaries = [];
          for (const ms of mixSummaries) {
            if (ms.songId !== mixFile.fileName) {
              newMixSummaries.push(ms);
            }
          }
          setMixSummaries(newMixSummaries);

          sendMixSummary(mixFile);
        }}
      >
        {!summary ? <Loader /> : <MdInfoOutline />}
      </Button>
    </View> */}
          <View style={{ flex: 1 }}>
            {renderTextArea(mix, fetchMixes, rows, compact)}
          </View>
          <View>
            <MDIconButton
              onMouseDown={(e) => {
                e.preventDefault();
                removeClick(mix, mixFile);
              }}
            >
              {confirmRemove ? (
                <MdDelete color="red" />
              ) : (
                <MDDeleteIcon />
              )}
            </MDIconButton>
            {confirmRemove && (
              <MDIconButton
                onMouseDown={(e) => {
                  e.preventDefault();
                  setConfirmRemove(false);
                }}
              >
                <MdCancel />
              </MDIconButton>
            )}
          </View>
        </Flex>
      </>
    );

    const compact = window.innerWidth < 600;
    if (compact) {
      return (
        <Card key={getMixCardId(fileName)} id={getMixCardId(fileName)}>
          {cover}
          {getMixDescription()}
          {renderSongs(summary)}
        </Card>
      );
    } else {
      return (
        <Card key={getMixCardId(fileName)} id={getMixCardId(fileName)}>
          <Flex>
            <Flex style={{ height: "200px", width: "200px" }}>{cover}</Flex>
            <Flex
              justifyContent={"space-between"}
              direction={"column"}
              flex="auto"
            >
              {getMixDescription(6)}
            </Flex>
          </Flex>
          {renderSongs(summary)}
        </Card>
      );
    }
  }
);

function App({ signOut, user }) {
  const [notes, setNotes] = useState([]);
  const [fileStatus, setFileStatus] = useState("");
  const [uploadingFileInProgress, setUploadingFileInProgress] = useState(false);
  const [uploadPercent, setUploadPercent] = useState(0);
  const [mixFiles, setMixFiles] = useState([]);
  const [playingMix, setPlayingMix] = useState<MixFile>();
  const [settingTrackAt, setSettingTrackAt] = useState<number>();
  const [settingTrackName, setSettingTrackName] = useState();
  const [settingTrackArtist, setSettingTrackArtist] = useState();
  const [playingMixSongs, setPlayingMixSongs] = useState<MixFile[]>();
  const [playingTrack, setPlayingTrack] = useState<MixFile>();
  const [updatingSong, setUpdatingSong] = useState();
  const [updatingSongArtist, setUpdatingSongArtist] = useState();
  const [updatingSongTitle, setUpdatingSongTitle] = useState();
  const [mixSummaries, setMixSummaries] = useState([]);
  const [confirmDeleteSong, setConfirmDeleteSong] = useState(false);
  const [renderPlayingMixView, setRenderPlayingMixView] = useState(false);
  const [sizeTotal, setSizeTotal] = useState<number>();
  const [sharedMixFiles, setSharedMixFiles] = useState([]);
  const [sharedNotes, setSharedNotes] = useState([]);
  const [scrollToMix, setScrollToMix] = useState<string | undefined>();

  const audio = useRef<HTMLAudioElement>();

  useEffect(() => {
    webSocket.verifyConnection();
  }, []);

  // #region Play audio
  const updateMetadata = useCallback(() => {
    log("metadata", playingMix, notes);
    const note =
      playingMix && notes.find((n) => n.file === playingMix.fileName);

    const title = playingTrack
      ? playingTrack.title
      : playingMix
      ? playingMix.fileName
      : "";
    const artist = playingTrack ? playingTrack.artist : "";
    const album = note ? note.text : playingMix ? playingMix.fileName : "";
    const artwork = [
      {
        src: "https://mixer.mixpiggy.com/192x192.png",
        sizes: "192x192",
        type: "image/png",
      },
      {
        src: "https://mixer.mixpiggy.com/512x512.png",
        sizes: "512x512",
        type: "image/png",
      },
    ];
    // Does flicker on old iOS, but on new it apparently doesn't set it otherwise
    // if (
    //   navigator.mediaSession?.metadata?.title === title &&
    //   navigator.mediaSession?.metadata?.artist === artist &&
    //   navigator.mediaSession?.metadata?.album === album
    // ) {
    //   return;
    // }

    navigator.mediaSession.metadata = new MediaMetadata({
      title,
      artist,
      album,
      artwork,
    });
  }, [playingMix, playingTrack, notes]);
  const playTrack = useCallback(
    (mixFile) => {
      if (!audio.current) {
        return;
      }
      audio.current.src = mixFile;
      audio.current
        .play()
        .then((_) => updateMetadata())
        .catch((error) => console.error(error));
    },
    [updateMetadata]
  );
  // #endregion Play audio

  // #region Web sockets
  const [isConnected, setIsConnected] = useState(webSocket.isConnected());
  //useEffect(() => {
  webSocket.onStateChange((connected) => setIsConnected(connected));
  //}, [setIsConnected]);
  const updateSummaryInMixSummaries = useCallback(
    (data) => {
      const newMixSummaries = [];
      for (const ms of mixSummaries) {
        if (ms.songId !== data.songId) {
          newMixSummaries.push(ms);
        }
      }
      newMixSummaries.push(data);
      setMixSummaries(newMixSummaries);
    },
    [mixSummaries]
  );
  const onSocketMessage = useCallback(
    (dataStr) => {
      const data = JSON.parse(dataStr);
      log("web sock", data);
      songCacheStorage.setItem("song--" + data.songId, JSON.stringify(data));
      if (data.summary) {
        log("only summary");
        updateSummaryInMixSummaries(data);
      } else {
        if (data.songs?.Items) {
          setPlayingMixSongs(data.songs.Items);
          updateSummaryInMixSummaries(data);
        } else {
          console.error(data);
        }
      }
    },
    [updateSummaryInMixSummaries]
  );
  useEffect(() => {
    webSocket.on(onSocketMessage);
    return () => webSocket.off(onSocketMessage);
  }, [onSocketMessage]);
  const sendPlayhead = useCallback((mixFile) => {
    log("playhead", mixFile);
    webSocket.send({
      action: "playhead",
      songId: mixFile.fileName,
      time: "0",
    });
  }, []);
  const sendMixSummary = (mixFile) => {
    log("sendMixSummary", mixFile);
    webSocket.send({
      action: "mixSummary",
      songId: mixFile.fileName,
    });
  };
  const playhead = useCallback(
    (mixFile) => {
      // Prevent interval to reset playing song
      localStorage.removeItem(localStoragePlayHeadId);

      sendPlayhead(mixFile);
      const signedUrl = mixFile?.signedUrl;
      setPlayingMix(mixFile);
      setRenderPlayingMixView(true);
      playTrack(signedUrl);
    },
    [playTrack, sendPlayhead]
  );
  const addSong = useCallback((mixFile, time, artist, title) => {
    log("addSong", mixFile, time, time.toString());
    webSocket.send({
      action: "addSong",
      songId: mixFile.fileName,
      time: time.toString(),
      artist: artist || "",
      title: title || "",
    });
  }, []);
  const deleteSong = useCallback((mixFile, time) => {
    log("deleteSong", mixFile, time, time.toString());
    webSocket.send({
      action: "deleteSong",
      songId: mixFile,
      time: time.toString(),
    });
  }, []);
  const updateSong = useCallback(
    (mixFile, song, updatedTitle, updatedArtist) => {
      log("updateSong", mixFile, song, updatedTitle, updatedArtist);
      webSocket.send({
        action: "updateSong",
        songId: mixFile,
        time: song.time.toString(),
        title: updatedTitle || song.title || "",
        artist: updatedArtist || song.artist || "",
      });
      setUpdatingSong(undefined);
      setUpdatingSongArtist(undefined);
      setUpdatingSongTitle(undefined);
    },
    []
  );
  // #endregion

  // #region Set last played from local storage
  const localStoragePlayHeadId = "playHead";
  useEffect(() => {
    const playHeadInterval = setInterval(() => {
      if (playingMix && audio.current && !audio.current.paused) {
        localStorage.setItem(
          localStoragePlayHeadId,
          JSON.stringify({
            mixFile: playingMix.fileName,
            currentTime: audio.current?.currentTime || 0,
          })
        );
      }
    }, 3 * 1000);
    return () => clearInterval(playHeadInterval);
  }, [playingMix]);
  const getLastPlayedMix = () => {
    let mixFile = getMixFile();
    if (mixFile) {
      return {
        mixFile,
        currentTime: 0,
      };
    }

    const ls = localStorage.getItem(localStoragePlayHeadId);
    if (ls) {
      try {
        return JSON.parse(ls);
      } catch {}
    }
    return undefined;
  };
  useEffect(() => {
    if (audio.current && !audio.current.currentTime) {
      const lastPlayed = getLastPlayedMix();
      const isSharedLink = getMixFile();
      const lastPlayedMix = !isSharedLink
        ? mixFiles.find((mixFile) => mixFile.fileName === lastPlayed?.mixFile)
        : sharedMixFiles.find(
            (mixFile) => mixFile.fileName === lastPlayed?.mixFile
          );
      if (lastPlayedMix) {
        setPlayingMix(lastPlayedMix);
        playhead(lastPlayedMix);
        audio.current.currentTime = lastPlayed.currentTime;
      }
    }
  }, [mixFiles, sharedMixFiles, audio, playhead]);
  // #endregion Set last played from local storage

  const listSharedObjectsFromS3 = useCallback(async () => {
    let identityId = getUserId();
    const config: AWSStorageConfig = { level: "protected" };
    if (identityId) {
      config.identityId = identityId;
    }

    const s3Objects = await Storage.list("", config);
    const mixes = [];

    for (const item of s3Objects) {
      let signedUrl = await generateDownloadLinks(
        item.key,
        "protected",
        identityId
      );
      const mix = {
        fileName: item.key,
        signedUrl: signedUrl,
        size: item.size,
      };
      mixes.push(mix);
    }
    setSharedMixFiles(mixes);
  }, []);

  const listObjectsFromS3 = useCallback(async () => {
    let identityId = getUserId();

    const config: AWSStorageConfig = { level: s3level };
    if (identityId) {
      config.level = "protected";
      config.identityId = identityId;
    }
    const s3Objects = await Storage.list("", config);
    const mixes = [];
    const mixPlaylist = [];
    const initialWaitTime = 1000;
    const deltaWaitTimeInBetweenWebSockMessages = 1000;
    let timeout = 1;
    if (sortBy === sortByOrder.desc) {
      s3Objects.sort(
        (a, b) => b.lastModified.getTime() - a.lastModified.getTime()
      );
    } else {
      s3Objects.sort(
        (a, b) => a.lastModified.getTime() - b.lastModified.getTime()
      );
    }
    const mixSummariesFromCache = [];
    let s = 0;
    for (const item of s3Objects) {
      log("file from s3", item);
      let signedUrl = await generateDownloadLinks(item.key);
      const mix = {
        fileName: item.key,
        signedUrl: signedUrl,
        size: item.size,
      };
      s += item.size;
      mixes.push(mix);

      mixPlaylist.push({
        url: mix.signedUrl,
        title: mix.fileName,
        artist: 'Mix Piggy'
      });

      console.log("getting mix data...", mix);
      const cache = songCacheStorage.getItem("song--" + mix.fileName);
      if (cache) {
        console.log("... from cache", mix);
        mixSummariesFromCache.push(JSON.parse(cache));
      } else {
        console.log("... request from websocket", mix);
        setTimeout(
          () => sendMixSummary(mix),
          initialWaitTime + timeout++ * deltaWaitTimeInBetweenWebSockMessages
        );
      }
    }
    setSizeTotal(s);
    setMixFiles(mixes);
    setMixSummaries(mixSummariesFromCache);

    localStorage.setItem('playlist-Mix Piggy', JSON.stringify({ tracks: mixPlaylist }));

    await listSharedObjectsFromS3();

    return mixes;
  }, []);

  async function generateDownloadLinks(
    fileKey,
    level = s3level,
    identityId = undefined
  ) {
    const config: AWSStorageConfig = {
      level: level,
      expires: 60 * 60 * 24 * 7,
    };
    if (identityId) {
      config.identityId = identityId;
    }
    const signedUrl = await Storage.get(fileKey, config);
    log("mix", signedUrl);
    return signedUrl;
  }

  useEffect(() => {
    listObjectsFromS3();
  }, [listObjectsFromS3]);

  const uploadProgress = (args) => {
    const percent = args.loaded / args.total;
    setUploadPercent(percent);
  };

  const uploadFileWithStorage = useCallback(async (file) => {
    setFileStatus("Uploading file...");
    const result = await Storage.put(file.name, file, {
      contentType: file.type,
      level: s3level,
      progressCallback: uploadProgress,
    });
    log("file uploaded", result);
    setFileStatus("File uploaded");
  }, []);

  const removeFileWithStorage = async (
    file,
    identityId = undefined,
    fileRemovedText = undefined
  ) => {
    if (!file) {
      return;
    }
    setFileStatus("Removing file...");
    const config: AWSStorageConfig = {
      level: s3level,
    };

    if (identityId) {
      config.level = "protected";
      config.identityId = identityId;
    }

    const result = await Storage.remove(file.fileName, config);
    log("file removed", result);
    setFileStatus(fileRemovedText || "File removed");
  };

  const fetchNotes = useCallback(async () => {
    let identityId = getUserId();
    if (!identityId) {
      const result = (await API.graphql({
        query: listNotes,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as { data: { listNotes: { items: unknown[] } } };
      log("all mixes", result.data.listNotes.items);
      setNotes(result.data.listNotes.items);
      const resultShared = (await API.graphql({
        query: listSharedMixes,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      })) as { data: { listSharedMixes: { items: unknown[] } } };
      log("all shared  mixes", resultShared.data.listSharedMixes.items);
      setSharedNotes(resultShared.data.listSharedMixes.items);
    } else {
      const mixFile = getMixFile();

      const result = (await API.graphql(
        graphqlOperation(
          listSharedMixes,
          {
            filter: {
              file: {
                eq: mixFile,
              },
            },
          }
          //authMode: "AMAZON_COGNITO_USER_POOLS"
        )
      )) as { data: { listSharedMixes: { items: unknown[] } } };
      log("all shared  mixes", result.data.listSharedMixes.items);
      setNotes(result.data.listSharedMixes.items);
    }
  }, []);

  const uploadFile = useCallback(() => {
    return new Promise((resolve, reject) => {
      var input = document.getElementById("uploadInput") as HTMLInputElement;
      input.type = "file";

      input.onchange = async (e: any) => {
        const file = e.target.files[0];
        if (mixFiles.some((mixFile) => mixFile.fileName === file.name)) {
          // eslint-disable-next-line no-restricted-globals
          if (!confirm(`File ${file.name} already uploaded. Do you want to continue to add a mix and overwrite the existing file?`) == true) {
            reject(`File ${file.name} already uploaded`);
            return;
          }
        }
        setUploadingFileInProgress(true);
        try {
          await uploadFileWithStorage(file);
        } catch (error) {
          reject(error);
          return;
        } finally {
          setUploadingFileInProgress(false);
          setUploadPercent(0);
        }
        resolve({
          name: file.name,
        });
      };

      input.click();
    });
  }, [mixFiles, uploadFileWithStorage]);

  const jumpsToMix = (fileName) => {
    setScrollToMix(fileName);
    // Clear to prevent re-scrolling on next render
    setTimeout(() => {
      setScrollToMix(undefined);
    }, 3_000);
  }

  const handleUploadMix = useCallback(async () => {
    let file;
    try {
      file = await uploadFile();
    } catch (error) {
      setFileStatus(error);
      return;
    }

    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", file.type);
    // myHeaders.append('Authorization', `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`);

    // var requestOptions = {
    //   method: 'PUT',
    //   headers: myHeaders,
    //   body: file.content,
    //   redirect: 'follow'
    // };

    // const ok = await fetch(`https://tkv31gf989.execute-api.eu-central-1.amazonaws.com/dev/mixer-mp3-files/${file.name}`, requestOptions);
    // console.log(ok);

    await API.graphql({
      query: createNote,
      variables: { input: { text: "", file: file.name } },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    setFileStatus("");
    await fetchNotes();
    await listObjectsFromS3();
    jumpsToMix(file.name);
  }, [fetchNotes, uploadFile, listObjectsFromS3]);

  const handleDeleteNote = useCallback(
    async (note, file) => {
      await removeFileWithStorage(file);
      await API.graphql({
        query: deleteNote,
        variables: { input: { id: note.id } },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      await fetchNotes();
      await listObjectsFromS3();
      setFileStatus("");
    },
    [fetchNotes, listObjectsFromS3]
  );

  useEffect(() => {
    fetchNotes();
  }, [fetchNotes]);

  const getPlayingTrack = useCallback(() => {
    if (!playingMixSongs) {
      return undefined;
    }
    const trackAt = Math.round(audio.current?.currentTime);
    const sortedPlayingSongs = [...playingMixSongs].sort(
      (s1, s2) => s1.time - s2.time
    );
    for (
      let playingSongIndex = 0;
      playingSongIndex < sortedPlayingSongs.length;
      playingSongIndex++
    ) {
      const to = sortedPlayingSongs[playingSongIndex + 1]
        ? sortedPlayingSongs[playingSongIndex + 1].time
        : Number.POSITIVE_INFINITY;
      const from = sortedPlayingSongs[playingSongIndex].time;
      if (
        (from <= trackAt && trackAt < to) ||
        (trackAt < from && playingSongIndex === 0)
      ) {
        return sortedPlayingSongs[playingSongIndex];
      }
    }
    return sortedPlayingSongs[sortedPlayingSongs.length - 1];
  }, [playingMixSongs]);

  // #region Intervals

  // Update playing track
  useEffect(() => {
    const intervalId = setInterval(() => {
      const pt = getPlayingTrack();
      setPlayingTrack(pt);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [getPlayingTrack, setPlayingTrack]);

  // Reach out to the server to get back possible updates
  // Just in case we miss a message or an update in parallel
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (playingMix) {
        sendPlayhead(playingMix);
      }
    }, 10 * 1000);
    return () => clearInterval(intervalId);
  }, [playingMix, sendPlayhead]);

  // Update playing metadata, shown on lock screen on iOS
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateMetadata();
    }, 4 * 1000);
    return () => clearInterval(intervalId);
  }, [updateMetadata]);

  // Refresh token and signed URL's
  useEffect(() => {
    const intervalId = setInterval(async () => {
      log("Refresh token");
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession: CognitoUserSession = await Auth.currentSession();
        cognitoUser.refreshSession(
          currentSession.getRefreshToken(),
          async (err, session) => {
            await fetchNotes();
            await listObjectsFromS3();

            const userId = cognitoUser.attributes?.sub;
            const token = currentSession.getIdToken().getJwtToken();
            webSocket.setToken({
              userId,
              token,
            });
          }
        );
      } catch (e) {
        log("Unable to refresh Token", e);
      }
    }, 50 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, [fetchNotes, listObjectsFromS3]);

  const renderPlayingSongs = () => {
    const renderUpdateButtons = (song, isUpdatingSong) => {
      const isSharedLink = getMixFile();
      if (isSharedLink) {
        return null;
      }
      return (
        <>
          {" "}
          {useMd && (
            <div>
              <MDIconButton
                onMouseDown={(e) => {
                  e.preventDefault();
                  isUpdatingSong
                    ? updateSong(
                        playingMix.fileName,
                        song,
                        updatingSongTitle,
                        updatingSongArtist
                      )
                    : setUpdatingSong(song.time);
                }}
              >
                {isUpdatingSong ? <MDCheckIcon /> : <MDEditIcon />}
              </MDIconButton>
              {isUpdatingSong && (
                <MDIconButton
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setUpdatingSong(undefined);
                  }}
                >
                  <MDCancelIcon />
                </MDIconButton>
              )}
            </div>
          )}
          {!useMd && (
            <>
              <Button
                onMouseDown={(e) => {
                  e.preventDefault();
                  isUpdatingSong
                    ? updateSong(
                        playingMix.fileName,
                        song,
                        updatingSongTitle,
                        updatingSongArtist
                      )
                    : setUpdatingSong(song.time);
                }}
              >
                {isUpdatingSong ? (
                  <BsFillCheckCircleFill />
                ) : (
                  <BsPencilSquare />
                )}
              </Button>
              {isUpdatingSong && (
                <Button
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setUpdatingSong(undefined);
                  }}
                >
                  <MdCancel />
                </Button>
              )}
            </>
          )}
        </>
      );
    };
    const renderDeleteButtons = (song) => {
      const isSharedLink = getMixFile();
      return (
        <>
          {useMd && (
            <>
              {!isSharedLink && !confirmDeleteSong && (
                <MDIconButton
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setConfirmDeleteSong(true);
                  }}
                >
                  <MdDelete />
                </MDIconButton>
              )}
              {confirmDeleteSong && (
                <>
                  <MDIconButton
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setConfirmDeleteSong(false);
                      deleteSong(playingMix.fileName, song.time);
                    }}
                  >
                    <MdDelete color="red" />
                  </MDIconButton>
                  <MDIconButton
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setConfirmDeleteSong(false);
                    }}
                  >
                    <MdCancel />
                  </MDIconButton>
                </>
              )}
            </>
          )}
          {!useMd && (
            <>
              {!isSharedLink && !confirmDeleteSong && (
                <Button
                  onMouseDown={(e) => {
                    e.preventDefault();
                    setConfirmDeleteSong(true);
                  }}
                >
                  <RiDeleteBin2Fill />
                </Button>
              )}
              {confirmDeleteSong && (
                <>
                  <Button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setConfirmDeleteSong(false);
                      deleteSong(playingMix.fileName, song.time);
                    }}
                  >
                    <RiDeleteBin2Fill color="red" />
                  </Button>
                  <Button
                    onMouseDown={(e) => {
                      e.preventDefault();
                      setConfirmDeleteSong(false);
                    }}
                  >
                    <MdCancel />
                  </Button>
                </>
              )}
            </>
          )}
        </>
      );
    };
    const renderSongTimeTitleArtist = (song, isUpdatingSong) => {
      const isSharedLink = getMixFile();
      return (
        <>
          {!isSharedLink && isUpdatingSong && (
            <View style={{ flex: 1 }}>
              <Text>{timeToString(song.time)}</Text>
              <TextField
                value={
                  updatingSongTitle !== undefined
                    ? updatingSongTitle
                    : song.title
                }
                placeholder="Title"
                onChange={(e) => setUpdatingSongTitle(e.currentTarget.value)}
                label={""}
              />
              <TextField
                value={
                  updatingSongArtist !== undefined
                    ? updatingSongArtist
                    : song.artist
                }
                placeholder="Artist"
                onChange={(e) => setUpdatingSongArtist(e.currentTarget.value)}
                label={""}
              />
            </View>
          )}
        </>
      );
    };
    return (
      <Flex direction={"column"}>
        {[...playingMixSongs]
          .sort((s1, s2) => s1.time - s2.time)
          .map((song) => {
            const isCurrentlyPlaying =
              playingTrack &&
              playingTrack.title === song.title &&
              playingTrack.artist === song.artist &&
              playingTrack.time === song.time;
            const isUpdatingSong = updatingSong === song.time;
            return (
              <Card key={song.time}>
                <Flex>
                  <View>
                    {useMd && (
                      <MDIconButton
                        disabled={isCurrentlyPlaying}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          if (audio.current) {
                            audio.current.currentTime = song.time;
                          }
                        }}
                      >
                        {isCurrentlyPlaying ? (
                          <MDPlayIcon className={"animate-ping"} />
                        ) : (
                          <BsFillPlayFill />
                        )}
                      </MDIconButton>
                    )}
                    {!useMd && (
                      <Button
                        disabled={isCurrentlyPlaying}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          if (audio.current) {
                            audio.current.currentTime = song.time;
                          }
                        }}
                      >
                        {isCurrentlyPlaying ? (
                          <BsFillPlayFill className={"animate-ping"} />
                        ) : (
                          <BsFillPlayFill />
                        )}
                      </Button>
                    )}
                  </View>
                  {!isUpdatingSong && (
                    <View style={{ flex: 1 }}>
                      <Text>
                        {timeToString(song.time)}{" "}
                        {formatTitleArtist(song.title, song.artist)}
                      </Text>
                    </View>
                  )}
                  {renderSongTimeTitleArtist(song, isUpdatingSong)}
                  {!confirmDeleteSong && (
                    <View>{renderUpdateButtons(song, isUpdatingSong)}</View>
                  )}
                  {!isUpdatingSong && <View>{renderDeleteButtons(song)}</View>}
                </Flex>
              </Card>
            );
          })}
      </Flex>
    );
  };

  const renderHeader = () => {
    const renderConnected = () => (
      <Badge size="small">
        {isConnected ? (
          <BsFillCloudCheckFill size="14px" />
        ) : (
          <Loader size="small" />
        )}
      </Badge>
    );
    const renderHeading = (level, withLinkToHome = undefined) => {
      const getImg = () => (
        <img
          onClick={(e) => {
            (e.target as HTMLImageElement).style.height = '5em';
            setTimeout(() => (e.target as HTMLImageElement).style.height = '1em', 2000);
          }}
          src={"new-mixpiggy.png"}
          alt="Mix Piggy"
          style={{
            objectFit: "contain",
            display: "block",
            height: "1.0em",
            //position: "absolute",
            //width: "0.8em",
            transform: "scale(3.5) translateY(5px) translateX(5px)",
            marginRight: "1.5em",
            marginLeft: "0.2em",
          }}
        />
      );
      const content = (
        <Flex>
          <div>{getImg()}</div>
          <Heading level={level}>Mix Piggy</Heading>
        </Flex>
      );
      if (withLinkToHome) {
        return <Link href="/">{content}</Link>;
      }
      return content;
    };
    const isSharedLink = getMixFile();
    return (
      <Flex justifyContent={"space-between"}>
        {fileStatus && (
          <div style={{ position: "fixed", zIndex: 1, right: "5px", top: headerHeight }}>
            <Alert
              isDismissible={uploadPercent === 0}
              variation="info"
              onDismiss={() => setFileStatus("")}
            >
              {fileStatus}
              {uploadPercent > 0 && ` ${(uploadPercent * 100).toFixed(1)}%`}
            </Alert>
          </div>
        )}
        {!isSharedLink && !renderPlayingMixView && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              height: headerHeight,
              width: "100%",
              justifyContent: "space-between",
              maxWidth,
              margin: "0px auto",
              zIndex: "1",
            }}
            className={"mp-header"}
          >
            {renderHeading(4)}
            <Flex className="headerButtons" gap="1rem">
              <View>
                <MDIconButton>
                  <MDDiscFull
                    onMouseDown={() => window.location.href = "meax/" }
                  />
                </MDIconButton>
                <MDIconButton
                  //size={"small"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    handleUploadMix();
                  }}
                  disabled={uploadingFileInProgress}
                >
                  {uploadingFileInProgress ? (
                    <>
                      <Loader />
                      <MDUploadFile />
                    </>
                  ) : (
                    <MDUploadFile />
                    // <>
                    //   <BsCloudUpload style={{ marginRight: "4px" }} />
                    //   {/* <Text className="title-button-text">Upload Mix</Text> */}
                    // </>
                  )}
                </MDIconButton>
              </View>
              <View>
                <Text style={{ fontSize: "12px", marginTop: "4px", marginRight: "4px" }}>
                  {sizeTotal ?
                    `${(sizeTotal / 1000 / 1000 / 1000).toFixed(1)}GB` : '0GB'}
                </Text>
                <Text variation={"info"} style={{ fontSize: "8px" }}>
                  {"v0.23"}
                </Text>
              </View>
              <View className="render-connected">{renderConnected()}</View>
              <View>
                <ToggleButtonGroup
                  className="themeButtons"
                  value={colorMode}
                  isExclusive
                  onChange={(value: ColorMode) => {
                    if (!value || colorMode === value) {
                      return;
                    }
                    setColorMode(value);
                    if (value === "dark") {
                      document.body.classList.toggle("dark-theme");
                      document.body.classList.remove("light-theme");
                    } else if (value === "light") {
                      document.body.classList.toggle("light-theme");
                      document.body.classList.remove("dark-theme");
                    } else {
                      document.body.classList.remove("dark-theme");
                      document.body.classList.remove("light-theme");
                    }
                  }}
                >
                  <ToggleButton
                    value="light"
                    style={{ borderRadius: "1px 0 0 1px" }}
                  >
                    <BsSun />
                  </ToggleButton>
                  <ToggleButton value="dark" style={{ borderRadius: "0" }}>
                    <MdDarkMode />
                  </ToggleButton>
                  <ToggleButton
                    value="system"
                    style={{ borderRadius: "0 1px 1px 0" }}
                  >
                    <BsCircleHalf />
                  </ToggleButton>
                </ToggleButtonGroup>
              </View>
              <View>
                <MDIconButton
                  size={"small"}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    signOut();
                  }}
                >
                  <MDLogout />
                  {/* <FaSignOutAlt style={{ marginRight: "4px" }} />
                  <Text className="title-button-text">Sign Out</Text> */}
                </MDIconButton>
              </View>
            </Flex>
          </div>
        )}
        {renderPlayingMixView && (
          <div
            style={{
              position: "fixed",
              display: "flex",
              height: headerHeight,
              width: "100%",
              justifyContent: "space-between",
              maxWidth,
              margin: "0px auto",
              padding: "5px",
              zIndex: "1",
            }}
            className={"mp-header"}
          >
            {renderHeading(4, isSharedLink)}
            <View className="render-connected">{renderConnected()}</View>
          </div>
        )}
      </Flex>
    );
  };

  const cache = new CellMeasurerCache({
    defaultHeight: 24,
    fixedWidth: true,
  });

  const renderMixList = (mixImages) => {
    const isSharedLink = getMixFile();
    if (isSharedLink || renderPlayingMixView) {
      return null;
    }

    const sortedNotes = [...notes].sort((a, b) =>
      sortBy === sortByOrder.desc
        ? Date.parse(b.createdAt) - Date.parse(a.createdAt)
        : Date.parse(a.createdAt) - Date.parse(b.createdAt)
    );

    const rowRenderer = ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      isScrolling, // The List is currently being scrolled
      isVisible, // This row is visible within the List (eg it is not an overscanned row)
      style, // Style object to be applied to row (to position it)
      parent,
    }) => {
      if (!isVisible) {
        log("Not rendering invisible row");
        return null;
      }
      const note = sortedNotes[index];

      const fileName = note.file;
      const mixFile = mixFiles.find((mixFile) => mixFile.fileName === fileName);
      const isSharedMix = sharedNotes.find(
        (sharedNote) => sharedNote.file === fileName
      );
      return (
        <CellMeasurer
          cache={cache}
          columnIndex={0}
          key={key}
          parent={parent}
          rowIndex={index}
        >
          <div style={style} key={key}>
            <Mix
              key={`mix-${note.id}`}
              //key={key}
              mix={note}
              mixFile={mixFile}
              fileName={fileName}
              fetchMixes={fetchNotes}
              handleDeleteMix={handleDeleteNote}
              playhead={playhead}
              sendMixSummary={sendMixSummary}
              mixSummaries={mixSummaries}
              setMixSummaries={setMixSummaries}
              mixImages={mixImages}
              isSharedMix={isSharedMix}
              audio={audio}
            />
          </div>
        </CellMeasurer>
      );
    };

    const scrollToIndex = sortedNotes.findIndex(n => n.file === scrollToMix);

    return (
      <Flex
        direction={"column"}
        style={{ marginTop: headerHeight, overflow: "hidden" }}
      >
        <div id="new-list-container" style={{ height: window.innerHeight }}>
          <AutoSizer>
            {({ width, height }) => (
              <List
                items={sortedNotes}
                width={width}
                height={height}
                rowCount={sortedNotes.length}
                rowHeight={cache.rowHeight}
                rowRenderer={rowRenderer}
                deferredMeasurementCache={cache}
                scrollToIndex={scrollToIndex}
                //overscanRowCount={10}
              />
            )}
          </AutoSizer>
        </div>
      </Flex>
    );
  };

  const maxWidth = "600px";
  const playingMixDivHeight = "110px";

  const renderAudioElement = () => {
    const backgroundPlayStyle: CSSProperties = {
      position: "fixed",
      bottom: "15px",
      margin: "0 auto",
      maxWidth,
    };
    return (
      <Flex>
        <audio
          ref={audio}
          id="audio"
          controls
          style={{
            display: playingMix ? "block" : "none",
            width: "100%",
            margin: "0",
            height: "31px",
            zIndex: 1,
            ...backgroundPlayStyle,
          }}
          onEnded={() => {
            // Play next
            let i = mixFiles.findIndex(
              (mixFile) => playingMix.fileName === mixFile.fileName
            );
            i++;
            const nextMix = mixFiles[i % mixFiles.length];
            playhead(nextMix);
          }}
          onError={() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }}
        >
          Your browser does not support the audio element.
        </audio>
      </Flex>
    );
  };

  const renderPlayingMixWithTimeTitleTrackAndEjectButton = () => {
    const titleAndArtist =
      playingTrack &&
      `${formatTitleArtist(playingTrack.title, playingTrack.artist)}`;

    const backgroundPlayStyle: CSSProperties = {
      display: playingMix ? "block" : "none",
      position: "fixed",
      bottom: "0",
      width: "100%",
      height: playingMixDivHeight,
      maxWidth,
      margin: "0 auto",
      padding: "10px 5px 65px 5px",
    };

    const note = notes.find((note) => note.file === playingMix?.fileName);
    const isSharedLink = getMixFile();
    return (
      <>
        <Card style={backgroundPlayStyle}>
          <Flex
            onClick={() =>
              !audio.current.paused
                ? audio.current.pause()
                : audio.current.play()
            }
          >
            <View style={{ flex: 1, display: 'flex', height: '85px', overflow: 'hidden' }}>
              {playingMix && (
                <Text as="span" style={{ paddingRight: "10px" }}>
                  {
                    //!audio.current.paused ?
                    <BsFillPlayFill className={"animate-ping"} />
                    // : <BsPauseCircle />
                  }
                </Text>
              )}
              <Text as="span">
                {playingMix
                  ? `${
                      note && note.text
                        ? note.text.split(/\r?\n/)[0]
                        : isSharedLink
                        ? playingMix.fileName.replace(/\.[^/.]+$/, "")
                        : playingMix.fileName
                    }${`${titleAndArtist ? ` — ${titleAndArtist}` : ""}`}`
                  : ""}
              </Text>
            </View>
            <View>
              <Flex>
                {!renderPlayingMixView && playingMix && (
                  <View>
                    <MDIconButton
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setRenderPlayingMixView(true);
                      }}
                    >
                      {/* <BsFillPlayFill class={"animate-ping"} /> */}
                      <MDEditIcon />
                    </MDIconButton>
                  </View>
                )}
                {/* {!renderPlayingMixView && playingMix && !audio.current?.paused && (
                <View>
                  <Button
                    onClick={() => {
                      audio.current?.pause();
                    }}
                  >
                    <FaPauseCircle />
                  </Button>
                </View>
              )} */}
                {renderPlayingMixView && playingMix && !isSharedLink && (
                  <View>
                    <MDIconButton
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setRenderPlayingMixView(false);
                        setTimeout(() => jumpsToMix(playingMix.fileName), 0);
                      }}
                    >
                      <MDListIcon />
                    </MDIconButton>
                  </View>
                )}
                {playingMix && !isSharedLink && (
                  <View>
                    <MDIconButton
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setTimeout(() => jumpsToMix(playingMix.fileName), 0);
                        setRenderPlayingMixView(false);
                        setPlayingMix(undefined);
                        setPlayingMixSongs(undefined);
                        audio.current?.pause();
                        localStorage.removeItem(localStoragePlayHeadId);
                      }}
                    >
                      <MDEjectIcon />
                    </MDIconButton>
                  </View>
                )}
              </Flex>
            </View>
          </Flex>
        </Card>
        {renderAudioElement()}
      </>
    );
  };

  const renderPlayingMix = () => {
    const shared = sharedMixFiles.find(
      (mixFile) => mixFile.fileName === playingMix?.fileName
    );
    const sharedNote = sharedNotes.find(
      (sharedNote) => sharedNote.file === playingMix?.fileName
    );
    const isSharedLink = getMixFile();
    const note = notes.find((n) => n.file === playingMix?.fileName);
    const padding = '20px';
    const paddingBottom = '140px';
    const saveTrack = (playingMix, settingTrackAt, settingTrackArtist, settingTrackName) => {
      addSong(
        playingMix,
        settingTrackAt,
        settingTrackArtist,
        settingTrackName
      );
      setSettingTrackAt(undefined);
      setSettingTrackName(undefined);
      setSettingTrackArtist(undefined);
    };
    const renderSaveTrackSection = () => {
      return renderPlayingMixView && playingMix && !isSharedLink && (
        <>
          {settingTrackAt && (
            <Card>
              <Text>At {timeToString(settingTrackAt)} seconds</Text>
              <TextField
                placeholder="Track"
                onChange={(e) =>
                  setSettingTrackName(e.currentTarget.value)
                }
                label={""}
              ></TextField>
              <TextField
                placeholder="Artist"
                onChange={(e) =>
                  setSettingTrackArtist(e.currentTarget.value)
                }
                label={""}
              ></TextField>
              <MDIconButton
                onMouseDown={(e) => {
                  e.preventDefault();
                  setSettingTrackAt(undefined);
                  setSettingTrackName(undefined);
                  setSettingTrackArtist(undefined);
                }}
              >
                <MDCancelIcon />
              </MDIconButton>
              {settingTrackAt && 
                <MDIconButton
                  onMouseDown={(e) => {
                    e.preventDefault();
                    saveTrack(
                      playingMix,
                      settingTrackAt,
                      settingTrackArtist,
                      settingTrackName
                    );
                  }}
                >
                  <MDCheckIcon />
                </MDIconButton>
              }
            </Card>
          )}
          <MDButton
            //variation={settingTrackAt ? "primary" : undefined}
            onMouseDown={(e) => {
              e.preventDefault();
              const trackAt = Math.round(audio.current.currentTime);
              log("current track", trackAt);
              if (!settingTrackAt && trackAt > 0) {
                setSettingTrackAt(trackAt);
              } else {
                saveTrack(
                  playingMix,
                  settingTrackAt,
                  settingTrackArtist,
                  settingTrackName
                );
              }
            }}
          >
            {settingTrackAt ? "Save track" : "Name playing track"}
          </MDButton>
        </>
      );
    };
    const renderShareButtonSection = () => {
      return renderPlayingMixView && playingMix && !isSharedLink && (
        <MDButton
          onMouseDown={async (e) => {
            e.preventDefault();
            const credentials = await Auth.currentUserCredentials();
            if (!sharedNote) {
              setFileStatus("Sharing file...");
              const n = notes.find((n) => n.file === playingMix.fileName);
              log("share", playingMix, n);

              if (
                sharedMixFiles.some(
                  (mixFile) => mixFile.fileName === playingMix.fileName
                )
              ) {
                setFileStatus(
                  `File ${playingMix.fileName} already shared`
                );
                return;
              }

              await Storage.copy(
                {
                  key: playingMix.fileName,
                  level: "private",
                },
                {
                  key: playingMix.fileName,
                  level: "protected",
                }
              );
              const cognitoUser = await Auth.currentAuthenticatedUser();
              const userId = cognitoUser.attributes?.sub;
              await API.graphql({
                query: createSharedMix,
                variables: {
                  input: {
                    text: n.text,
                    file: playingMix.fileName,
                    userId: userId,
                    identityId: credentials.identityId,
                  },
                },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              await listSharedObjectsFromS3();
              await fetchNotes();
              setFileStatus("File shared");
            } else {
              await removeFileWithStorage(
                playingMix,
                credentials.identityId,
                "File unshared"
              );
              await API.graphql({
                query: deleteSharedMix,
                variables: { input: { id: sharedNote.id } },
                authMode: "AMAZON_COGNITO_USER_POOLS",
              });
              await listSharedObjectsFromS3();
              await fetchNotes();
            }
          }}
        >
          {!sharedNote ? "Share" : "Unshare"}
        </MDButton>
      );
    };
    const renderShareLinkSection = () => {
      return renderPlayingMixView &&
        playingMix &&
        !isSharedLink &&
        sharedNote && (
          <View>
            <TextField
              value={
                (sharedNote &&
                  `https://mixer.mixpiggy.com/?userId=${sharedNote.identityId}&mixFile=${playingMix.fileName}`) ||
                ""
              }
              readOnly={true}
              label={""}
            />
            <Text as="span">
              Share this link with your community to listen in
            </Text>
          </View>
        );
    }
    return (
      <>
        <div
          id="playing-mix-view"
          style={{
            paddingTop: headerHeight,
            paddingBottom: paddingBottom,
            paddingLeft: padding,
            paddingRight: padding,
            overflow: 'auto',
            height: '100dvh'
            //height: `calc(100dvh - ${playingMixDivHeight}`
          }}
        >
          {renderPlayingMixView && playingMix && (
            <div style={{ margin: "10px 0" }}>
              <MixCover
                mixFileName={playingMix.fileName}
                mixImages={mixImages}
                onNewImageUploaded={() => {
                  // eslint-disable-next-line no-restricted-globals
                  location.reload();
                }}
                audio={audio}
              />
            </div>
          )}

          <Flex direction={"column"}>
            {renderPlayingMixView && playingMix && playingMixSongs && (
              <>{renderPlayingSongs()}</>
            )}
            {renderSaveTrackSection()}
            {renderShareButtonSection()}
            {renderShareLinkSection()}
          </Flex>

          {renderPlayingMixView &&
            playingMix &&
            !isSharedLink &&
            note &&
            renderTextArea(note, fetchNotes, 6)}

          {isSharedLink && playingMix && (
            <Text>{playingMix.fileName.replace(/\.[^/.]+$/, "")}</Text>
          )}

          {!isSharedLink && renderPlayingMixView && playingMix && (
            <Flex justifyContent={"space-between"}>
              {renderDownloadLink(playingMix, playingMix.fileName)}
              {
                <Text>{`${(playingMix.size / 1000 / 1000).toFixed(
                  1
                )} MB`}</Text>
              }
            </Flex>
          )}
        </div>
        {renderPlayingMixWithTimeTitleTrackAndEjectButton()}
      </>
    );
  };

  const [colorMode, setColorMode] = useState<ColorMode>("system");
  const theme = {
    name: "my-theme",
    overrides: [defaultDarkModeOverride],
  };

  const { mixImages } = useMixImages(notes);

  const mainFlexStyle: CSSProperties = {
    maxWidth: maxWidth,
    margin: "0 auto",
    //height: "100vh",
    //height: "100dvh"
  };
  // if (renderPlayingMixView) {
  //   mainFlexStyle.height = `calc(100vh - ${playingMixDivHeight}`;
  //   mainFlexStyle.overflowY = "auto";
  // }

  const isDarkMode =
    colorMode === "system"
      ? window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      : colorMode === "dark";
  const mdTheme = createTheme({
    palette: {
      mode: isDarkMode ? "dark" : "light",
    },
  });

  return (
    <MDThemeProvider theme={mdTheme}>
      <CssBaseline />
      <ThemeProvider theme={theme} colorMode={colorMode}>
        <Flex direction={"column"} style={mainFlexStyle}>
          {renderHeader()}
          {renderMixList(mixImages)}
          {renderPlayingMix()}
          <input id="uploadInput" type="file" style={{ display: "none" }} />
        </Flex>
      </ThemeProvider>
    </MDThemeProvider>
  );
}

export default withAuthenticator(App);
