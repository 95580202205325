export function ReconnectingWebSocket(
  url: string,
  getToken: () => Promise<any>
) {
  let client: any;
  let isConnected = false;
  let reconnectOnClose = true;
  let messageListeners: any[] = [];
  let stateChangeListeners: any[] = [];
  let token: any;
  let userId: any;

  function setToken(t: any) {
    token = t;
  }

  function setUserId(id: any) {
    userId = id;
  }

  function on(fn: any) {
    messageListeners.push(fn);
  }

  function off(fn: any) {
    messageListeners = messageListeners.filter((l: any) => l !== fn);
  }

  function onStateChange(fn: any) {
    stateChangeListeners.push(fn);
    return () => {
      stateChangeListeners = stateChangeListeners.filter((l: any) => l !== fn);
    };
  }

  function setDisconnect() {
    isConnected = false;
    console.log("ws disconnect");
    stateChangeListeners.forEach((fn: any) => fn(false));
  }

  function start() {
    if (!token) {
      return;
    }

    if (!userId) {
      console.error("No userId");
      return;
    }

    if (isConnected) {
      setDisconnect();
    }

    reconnectOnClose = true;
    client = new WebSocket(`${url}?access_token=${token}`);

    client.onopen = () => {
      isConnected = true;
      console.log('ws opened');
      stateChangeListeners.forEach((fn: any) => fn(true));
    };

    client.onmessage = (event: any) => {
      messageListeners.forEach((fn: any) => fn(event.data));
    };

    client.onerror = (e: any) => console.error(e);

    client.onclose = () => {
      isConnected = false;
      stateChangeListeners.forEach((fn: any) => fn(false));

      if (!reconnectOnClose) {
        console.log("ws closed by app");
        return;
      }

      console.log("ws closed by server");

      setTimeout(start, 3000);
    };
  }

  function send(data: any) {
    if (!client) {
      // Send again later
      setTimeout(() => send(data), 3000);
      return;
    }
    if (client.readyState !== WebSocket.OPEN) {
      if (client.readyState !== WebSocket.CONNECTING) {
        start();
      }
      // Send again later
      setTimeout(() => send(data), 3000);
    } else {
      if (!userId) {
        console.error("No userId");
        return;
      }
      data.userId = userId;
      client.send(JSON.stringify(data));
    }
  }

  getToken().then(({ token, userId }: any) => {
    setToken(token);
    setUserId(userId);
    start();
  });

  // Close without reconnecting
  const close = () => {
    reconnectOnClose = false;
    client.close();
    setDisconnect();
  };

  function handleVisibilityChange() {
    console.log('handleVisibilityChange', document.visibilityState);
    if (document.visibilityState === "hidden") {
      close();
    } else {
      start();
    }
  }
  document.addEventListener("visibilitychange", handleVisibilityChange, false);

  return {
    on,
    off,
    onStateChange,
    close,
    getClient: () => client,
    isConnected: () => isConnected,
    send,
    setToken,
    verifyConnection: () => {
      if (token) {
        return;
      }
      getToken().then(({ token, userId }) => {
        setToken(token);
        setUserId(userId);
        start();
      });
    },
  };
}
